<template>
  <div class="header-graphic">
    <Title :top="newData.title.top" :name="newData.title.name" />
    <Legenda :legendas="data.legenda" />
  </div>
</template>

<script>
import Title from "@/components/title/Title.vue";
import Legenda from "@/components/legenda/Legenda.vue";

export default {
  props: ["data", "showDownload"],
  components: {
    Title,
    Legenda,
  },
  data() {
    return {
      newData: {
        title: {
          top: "",
          name: "",
        },
      },
    };
  },
  filters: {},
  methods: {},
  computed: {},
  watch: {
    data(newVal) {
      if (newVal) this.newData = newVal;
    },
    deep: true,
    immediate: true,
  },
};
</script>

<style lang="scss" scoped>
.header-graphic {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media only screen and (max-width: 991px) {
    .box-title {
      height: auto;
    }
  }
}
</style>
