<template>
  <div class="box-title">
    <div v-if="top" class="top">{{ top }}</div>
    <h1>{{ name }}</h1>
  </div>
</template>

<script>
export default {
  props: ["top", "name"],
  filters: {},
  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.box-title {
  position: relative;
  display: flex;
  flex-direction: column;
  // height: 72px;
  margin-bottom: 20px;
  //   &:after {
  //     content: "";
  //     width: 60px;
  //     height: 6px;
  //     background-color: #06379c;
  //   }
  .top {
    font-size: 18px;
    line-height: 18px;
  }
  h1 {
    font-weight: 600;
    font-size: 18px;
    margin: 0;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 991px) {
  .box-title {
    .top {
      font-size: 14px;
      line-height: 14px;
    }
    h1 {
      font-size: 15px;
    }
  }
}
</style>
