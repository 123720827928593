Number.prototype.before = function () {
  var value = parseInt(this.toString().split(".")[0], 10);//before
  return value ? value : 0;
}

Number.prototype.after = function () {
  var value = parseInt(this.toFixed(2).toString().split(".")[1], 10);//after
  return value ? value : 0;
}

export function numberToReal(value, prefixo = true) {
  if (!value) value = 0;
  var n = value.toFixed(2).toString();
  var numero = n.split(".");

  const pre = prefixo ? "R$ " : "";

  numero[0] = pre + numero[0].split(/(?=(?:...)*$)/).join(".");
  return numero.join(",");
}

export function numberToRealWitoutSymbol(value) {
  if (!value) value = 0;
  var n = value.toFixed(2).toString();
  var numero = n.split(".");
  numero[0] = numero[0].split(/(?=(?:...)*$)/).join(".");
  return numero.join(",");
}

export function numberToDollar(value) {
  if (!value) value = 0;
  var n = value.toFixed(2).toString();
  var numero = n.split(".");
  numero[0] = "$ " + numero[0].split(/(?=(?:...)*$)/).join(",");
  return numero.join(".");
}

export function numberWithK(value) {
  if (isNaN(value)) return 'NaN';
  if (value < 1000) return value.toString(); // Return the number as is if less than 1000
  const absNum = Math.abs(value); // Take the absolute value to work with positive numbers
  const suffixes = ['', 'K', 'M', 'B', 'T']; // Suffixes for thousands, millions, billions, trillions, etc.
  const tier = Math.floor(Math.log10(absNum) / 3); // Determine the tier (thousands, millions, etc.)
  const suffix = suffixes[tier]; // Select the appropriate suffix
  // Scale the number to its appropriate magnitude and round to 1 decimal place
  const scaledNum = (value / Math.pow(1000, tier)).toFixed(1);
  return scaledNum + suffix;
}
