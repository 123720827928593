<template>
  <div v-if="legendas">
    <div class="legenda">
      <div
        v-for="(legenda, index) in legendas"
        v-bind:key="index"
        class="item"
        v-bind:style="{ color: legenda.color, '--my-color-var': legenda.color }"
      >
        {{ legenda.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["legendas"],
  filters: {},
  methods: {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.legenda {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  .item {
    font-weight: 600;
    font-size: 18px;
    position: relative;
    font-weight: 600;
    display: flex;
    height: 20px;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
    &:before {
      content: "";
      width: 12px;
      height: 12px;
      display: block;
      background-color: var(--my-color-var);
      border-radius: 15px;
      margin-right: 10px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .legenda {
    .item {
      font-size: 14px;
    }
  }
}
</style>
